<template>
   <section class="inventory h-100">
      <div class="h-100">
         <div class="grid-margin h-100">
            <div class="h-100 container-fluid px-5">
                
                  <!--v-click-outside="hide_prof_tree"-->
                <div class='h-100 row mt-5 justify-content-between'  v-if='$store.state.system[cdata.system._route_].is_all_fin_for_this_page'>
                    <div class="col-12 col-md-4 col-lg-6">
                        <div class="row">
                            
                            <div class="col-12 col-sm-6 col-md-12 col-lg-6 prof_card__outer" v-for="profession in state.player_professions" :key="profession.prof_id">
                                <div :class="((profession.is_active) ? 'p-3 d-flex flex-column justify-content-between prof_card active' : 'p-3 d-flex flex-column justify-content-between prof_card')">
                                        
                                        <div class="lvl_cover_element" :current_id=profession.prof_id style="cursor:pointer; position: absolute; top: 0; left: 0; height: 100%; width: 100%; z-index: 2;" @click="show_prof_tree"></div>
                                        <div class="d-flex align-items-center justify-content-between prof_card__header">
                                            <div class="d-flex align-items-center">
                                                <img :src="profession.prof_ico">
                                                <p class="ml-2 prof_card__header__prof_name" v-html="profession.name"></p>
                                            </div>
                                            <div class="prof_card__header__prof_lvl_outer">
                                                <p class="prof_card__header__prof_lvl">{{profession.lvl_info.current_lvl}} lvl</p>
                                            </div>
                                        </div>

                                        <div>
                                            <img :src="profession.prof_picture" class="w-100 prof_picture_row">
                                        </div>

                                        <div class="d-flex justify-content-around">
                                            <div v-for="prof_resource in profession.prof_resources" :key="prof_resource.item_id" class="prof_card_resources">
                                                
                                                <div class="custom_tooltip" data-side="bottom">
                                                    <div class="d-flex align-items-center justify-content-center">
                                                        <img :src="prof_resource.item_img" class="w-100">
                                                    </div>
                                                    <span class="tooltiptext">{{prof_resource.item_name}}</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        
                                        <div v-if="profession.is_active" class="active_prof_signboard__outer">
                                            <div class="active_prof_signboard">
                                                <p>Active</p>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>
                    
                    
                    <transition name="fade" :duration="{ enter: 500, leave: 800 }">
                        <div v-if="cdata.business_logic.selected_profession != null" class="col-12 col-md-7 col-lg-6">
                            <div class="prof_tree">
                                <div class="prof_tree__header d-flex align-items-center justify-content-between">

                                    <div class="d-flex align-items-center justify-content-between prof_tree__header_info">
                                        <div class="d-flex align-items-center">
                                            <img :src="cdata.business_logic.selected_profession.prof_ico">
                                            <p class="ml-2 prof_tree__header_info__prof_name" v-html="cdata.business_logic.selected_profession.name"></p>
                                        </div>
                                        <div class="prof_tree__header_info_lvl_outer ml-3">
                                            <p class="prof_tree__header_info_lvl">{{cdata.business_logic.selected_profession.lvl_info.current_lvl}} / {{cdata.business_logic.selected_profession.max_level}} lvl</p>
                                        </div>
                                    </div>

                                    <div class="d-flex align-items-center prof_tree__header_resources">
                                        <div class="mx-1" v-for="prof_resources in cdata.business_logic.selected_profession.prof_resources" :key="prof_resources.item_id">
                                            
                                                <div class="custom_tooltip" data-side="bottom">
                                                    <div class="d-flex align-items-center justify-content-center">
                                                        <img :src="prof_resources.item_img">
                                                    </div>
                                                    <span class="tooltiptext">{{prof_resources.item_name}}</span>
                                                </div>
                                            
                                            
                                        </div>
                                    </div>

                                </div>
                                
                                <div class="prof_tree__content mt-5">
                                    <div class="row justify-content-between">
                                        <div class="col-3 d-flex flex-column align-items-center prof_tree__content__formulas">
                                            <div class="prof_tree__content_header d-flex align-items-center justify-content-center">
                                                <img src="/resource/img/game_ico/extensions_ico/Formulas_category.svg" alt="fomulas_category_ico">
                                                <p class="ml-2">Formulas</p>
                                            </div>
                                            
                                            <div class="d-flex justify-content-center prof_tree__content_main mt-4">
                                                <div class="wrap">
                                                    
                                                    <div v-for="tree_formula in form_prof_tree_lvls.Formulas" :key="tree_formula[0]" 
                                                         :class="(tree_formula[1]) ? 'box test' : 'box separator'">
                                                        <div class="lvl_line"></div>
                                                        
                                                        <div v-if="tree_formula[1] && (cdata.business_logic.selected_profession.lvl_info.current_lvl >= Number(tree_formula[0]))" 
                                                             class="general_extension_button_outer">
                                                            
                                                            <div :class="(Number(tree_formula[1].item_count) > 0) ? 'general_extension_button' : 'general_extension_button_unlocked general_extension_button__front'"
                                                                 @click="show_extension_modal(tree_formula[0],'Formula', tree_formula[1])">
                                                                <p><i :class="(Number(tree_formula[1].item_count) > 0) ? 'mdi mdi-checkbox-marked' : 'mdi mdi-cart'"></i> Tier {{tree_formula[1].tier}}</p>
                                                                <p class="general_extension_button__back">{{tree_formula[1].unlock_cost}} <img class="ml-1" src="/resource/img/game_ico/exp.svg" alt="image" style="width: 24px;" /></p>
                                                            </div>
                                                        </div>
                                                        
                                                        <div v-else-if="tree_formula[1] && (cdata.business_logic.selected_profession.lvl_info.current_lvl < Number(tree_formula[0]))" 
                                                             class="general_extension_button_outer">
                                                            
                                                            <div class="general_extension_button locked general_extension_button__front" 
                                                                 @click="show_extension_modal(tree_formula[0],'Formula', tree_formula[1])">
                                                                <p><i class="mdi mdi-lock"></i> Tier {{tree_formula[1].tier}}</p>
                                                                <p class="general_extension_button__back"><i class="mdi mdi-lock mr-1"></i> {{tree_formula[0]}} lvl</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3 offset-1 d-flex flex-column align-items-center prof_tree__content__items">
                                            <div class="prof_tree__content_header d-flex align-items-center justify-content-center">
                                                <img src="/resource/img/game_ico/extensions_ico/Items_category.svg" alt="items_category_ico">
                                                <p class="ml-2">Items</p>
                                            </div>
                                            
                                            <div class="d-flex justify-content-center prof_tree__content_main mt-4">
                                                <div class="wrap">

                                                    
                                                    <div v-for="tree_Item in form_prof_tree_lvls.Items" :key="tree_Item[0]" 
                                                         :class="(tree_Item[1]) ? 'box test' : 'box separator'">
                                                        <div class="lvl_line"></div>
                                                        
                                                        <div v-if="tree_Item[1] && (cdata.business_logic.selected_profession.lvl_info.current_lvl >= Number(tree_Item[0]))" 
                                                             class="general_extension_button_outer">
                                                            
                                                            <div :class="(Number(tree_Item[1].item_count) > 0) ? 'general_extension_button' : 'general_extension_button_unlocked general_extension_button__front'"
                                                                 @click="show_extension_modal(tree_Item[0],'Item', tree_Item[1])">
                                                                <p><i :class="(Number(tree_Item[1].item_count) > 0) ? 'mdi mdi-checkbox-marked' : 'mdi mdi-cart'"></i> Tier {{tree_Item[1].tier}}</p>
                                                                <p class="general_extension_button__back">{{tree_Item[1].unlock_cost}} <img class="ml-1" src="/resource/img/game_ico/exp.svg" alt="image" style="width: 24px;" /></p>
                                                            </div>
                                                        </div>
                                                        
                                                        <div v-else-if="tree_Item[1] && (cdata.business_logic.selected_profession.lvl_info.current_lvl < Number(tree_Item[0]))" 
                                                             class="general_extension_button_outer">
                                                            
                                                            <div class="general_extension_button locked general_extension_button__front" 
                                                                 @click="show_extension_modal(tree_Item[0],'Item', tree_Item[1])">
                                                                <p><i class="mdi mdi-lock"></i> Tier {{tree_Item[1].tier}}</p>
                                                                <p class="general_extension_button__back"><i class="mdi mdi-lock mr-1"></i> {{tree_Item[0]}} lvl</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-3 offset-1 d-flex flex-column align-items-center prof_tree__content__boosters">
                                            <div class="prof_tree__content_header d-flex align-items-center justify-content-center">
                                                <img src="/resource/img/game_ico/extensions_ico/Boosters_category.svg" alt="boosters_category_ico">
                                                <p class="ml-2">Boosters</p>
                                            </div>
                                            
                                            <div class="d-flex justify-content-center prof_tree__content_main mt-4">
                                                <div class="wrap">

                                                    
                                                    
                                                    <div v-for="tree_Booster in form_prof_tree_lvls.Boosters" :key="tree_Booster[0]" 
                                                         :class="(tree_Booster[1]) ? 'box test' : 'box separator'">
                                                        <div class="lvl_line"></div>
                                                        
                                                        <div v-if="tree_Booster[1] && (cdata.business_logic.selected_profession.lvl_info.current_lvl >= Number(tree_Booster[0]))" 
                                                             class="general_extension_button_outer">
                                                            
                                                            <div :class="(Number(tree_Booster[1].item_count) > -1) ? 'general_extension_button' : 'general_extension_button_unlocked general_extension_button__front'"
                                                                 @click="show_extension_modal(tree_Booster[0],'Booster', tree_Booster[1])">
                                                                <p><i :class="(Number(tree_Booster[1].item_count) > -1) ? 'mdi mdi-checkbox-marked' : 'mdi mdi-cart'"></i> Tier {{tree_Booster[1].tier}}</p>
                                                                <p class="general_extension_button__back">{{tree_Booster[1].unlock_cost}} <img class="ml-1" src="/resource/img/game_ico/exp.svg" alt="image" style="width: 24px;" /></p>
                                                            </div>
                                                        </div>
                                                        
                                                        <div v-else-if="tree_Booster[1] && (cdata.business_logic.selected_profession.lvl_info.current_lvl < Number(tree_Booster[0]))" 
                                                             class="general_extension_button_outer">
                                                            
                                                            <div class="general_extension_button locked general_extension_button__front" 
                                                                 @click="show_extension_modal(tree_Booster[0],'Booster', tree_Booster[1])">
                                                                <p><i class="mdi mdi-lock"></i> Tier {{tree_Booster[1].tier}}</p>
                                                                <p class="general_extension_button__back"><i class="mdi mdi-lock mr-1"></i> {{tree_Booster[0]}} lvl</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--<div></div>-->
                                </div>
                                
                            </div> 
                        </div>


                        <div v-else class="col-12 col-md-7 col-lg-6 d-flex flex-column mt-5 pt-5">
                            <img src="/resource/img/profession_images/empty_prof_extensions_list.svg">
                        </div>
                    </transition>
                    
                    
                    
 <!--TODO CLICK OUTSIDE OF MODAL-->                   
<!-- Modal -->
<div v-if="cdata.business_logic.selected_extension != null" class="custom-modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
      
       <!--v-click-outside="hide_extension_modal"-->
    <div class="modal-content">
        
      <div class="custom_modal__header">
        <div class="d-flex align-items-center">
            <img :src="'/resource/img/game_ico/extensions_ico/' + cdata.business_logic.selected_extension_type + 's_category.svg'" alt="category_ico" style="width: 40px;">
            <h5 class="modal-title ml-3" id="exampleModalLongTitle">{{cdata.business_logic.selected_extension_type}}</h5>
        </div>
          
<!--        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="hide_extension_modal">
          <span aria-hidden="true">&times;</span>
        </button>-->
        <div v-on:click="hide_extension_modal" class="d-flex align-items-center" style="cursor: pointer; padding: 10px; background: rgb(234 234 245); box-shadow: 0px 2px 0px #DCE0EA; border-radius: 12px; width: 44px; height: 44px;">
            <p style="font-size: 24px;"><i class="mdi mdi-close"></i></p>
        </div>
      </div>
        
        
      <div class="custom_modal__body">
          
        <div class="row mt-3" v-if="cdata.business_logic.selected_extension.usage_limit">
            <p class="col-6 custom_modal__body_tier">Tier {{cdata.business_logic.selected_extension.tier}}</p>
            <div class="col-6 d-flex justify-content-end align-items-center">
                <div class="booster_usage_limit">
                    <img class="mr-2" src="/resource/img/game_ico/extensions_ico/booster_ico.svg" alt="booster_ico">
                    <p>{{cdata.business_logic.selected_extension.usage_limit}} / {{cdata.business_logic.selected_extension.usage_limit}}</p>
                </div>
            </div>
        </div>
          
        <div class="row mt-1" v-else>
            <p class="col-12 custom_modal__body_tier">Tier {{cdata.business_logic.selected_extension.tier}}</p>
        </div>
          
          <div class="mt-2 d-flex justify-content-between align-items-center chain_part">
                <div class="d-flex align-items-center">
                    <div v-for="item in cdata.business_logic.selected_extension.what_to_give" :key="item.item_id" class="d-flex align-items-center">
                        <div class="custom_tooltip" data-side="bottom">
                            <div class="d-flex align-items-center justify-content-center">
                                <p class="mr-1">{{item.amount}}</p>
                                <img :src="item.item_img" alt="...">
                            </div>
                            <span class="tooltiptext">{{item.item_name}}</span>
                        </div>
                    </div>
                </div>
              
                <i class="mdi mdi-arrow-right" style="font-size: 36px;"></i>
              
                <div class="d-flex align-items-center">
                    <template v-for="item in cdata.business_logic.selected_extension.what_to_get">
                        <template v-for='j in (Number(item.amount))'>
                            <div class="custom_tooltip" data-side="bottom" :key='item.item_id + j'>
                                <div class="d-flex align-items-center justify-content-center">
                                    <img :src="item.item_img" class="ml-1" style="max-height: 40px;">
                                </div>
                                <span class="tooltiptext">{{item.item_name}}</span>
                            </div>
                        </template>
                    </template>
                </div>
              
                <div class="d-flex align-items-center">
                    <div class="custom_tooltip" data-side="bottom">
                        <div class="d-flex align-items-center justify-content-center">
                            <p class="chain_prof_exp mr-1">+{{cdata.business_logic.selected_extension.prof_exp_reward}}</p>
                            <img src="/resource/img/game_ico/prof_exp.svg" alt="image"/>
                        </div>
                        <span class="tooltiptext">Professional exp</span>
                    </div>
                </div>
          </div>
          
          <!--Activate BTN-->
          <div v-if="cdata.business_logic.selected_extension_type != 'Booster'">
                <router-link v-if="(Number(cdata.business_logic.selected_extension.item_count) > 0) &&
                            (Number(cdata.business_logic.selected_profession.lvl_info.current_lvl) >= Number(cdata.business_logic.selected_extension_unlock_lvl))"
                            v-bind:to="'/g1/desksweek/'+'?t='+$root._SYS().add_v()">
                    <button :class="'mt-4 btn_' + cdata.business_logic.selected_extension_type">Activate</button>
                </router-link>
              
              <button v-else-if="(Number(cdata.business_logic.selected_extension.item_count) <= 0) &&
                      (Number(cdata.business_logic.selected_profession.lvl_info.current_lvl) >= Number(cdata.business_logic.selected_extension_unlock_lvl))"
                :class="'mt-4 btn_' + cdata.business_logic.selected_extension_type"
                @click="unlock_new_extension">Buy {{cdata.business_logic.selected_extension.unlock_cost}} <img src="/resource/img/game_ico/exp.svg" alt="image" style="width: 24px;"/></button>
              
              <button v-else-if="
                      (Number(cdata.business_logic.selected_profession.lvl_info.current_lvl) < Number(cdata.business_logic.selected_extension_unlock_lvl))"
                      class="mt-4 btn_inactive"><i class="mdi mdi-lock"></i> Reach {{cdata.business_logic.selected_extension_unlock_lvl}} lvl</button>
          </div>
          
          <div v-else>
                <router-link v-if="(Number(cdata.business_logic.selected_extension.item_count) > -1) &&
                            (Number(cdata.business_logic.selected_profession.lvl_info.current_lvl) >= Number(cdata.business_logic.selected_extension_unlock_lvl))"
                            v-bind:to="'/g1/desksweek/'+'?t='+$root._SYS().add_v()">
                    <button :class="'mt-4 btn_' + cdata.business_logic.selected_extension_type">Activate</button>
                </router-link>
              
              <button v-else-if="(Number(cdata.business_logic.selected_extension.item_count) <= -1) &&
                      (Number(cdata.business_logic.selected_profession.lvl_info.current_lvl) >= Number(cdata.business_logic.selected_extension_unlock_lvl))"
                :class="'mt-4 btn_' + cdata.business_logic.selected_extension_type"
                @click="unlock_new_extension">Buy {{cdata.business_logic.selected_extension.unlock_cost}} <img src="/resource/img/game_ico/exp.svg" alt="image" style="width: 24px;"/></button>
              
              <button v-else-if="
                      (Number(cdata.business_logic.selected_profession.lvl_info.current_lvl) < Number(cdata.business_logic.selected_extension_unlock_lvl))"
                      class="mt-4 btn_inactive"><i class="mdi mdi-lock"></i> Reach {{cdata.business_logic.selected_extension_unlock_lvl}} lvl</button>
          </div>
          
      </div>
        
    </div>
  </div>
</div> 
        
 <!--END Modal--> 
                    
                    
                    
                </div>

            </div>
         </div>
      </div>
   </section>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
    
            var cdata = {//Если не указать - не всключиться реактивность 
                /* COPYPASTE MODIFY */
                system: {
                    _route_: "g1_professions", /* MODIFY */
                    is_section: {// store/index  -> is_required_sections
                        center: false, /* СЕКЦИИ НА СТРАНИЦЕ MODIFY  v-if='cdata.system.is_section.center'   */
                    },
                    is_stage: {// store/index  -> is_required_stages
                        center: false,
                    },
                    cnf: {},
                    buttons: {
                        common: {
                            is_button_blocked: true,
                            button_blocking_exeption_list: {}, //ДЛЯ ДЕФОЛТНЫХ КНОПОК!!!
                        },
                        common_without_symmary: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        //0: "r_auth", //r_auth  
                                        0: "json_version", //r_auth 
                                        1: "events_list", //r_auth 
                                        //2: "json_layout_xxxx__pos_xx", НЕТ, просто не добавлять на эту страницу динамические таблицы!!!
                                    }
                        },
                        button1: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        0: "r_auth", //r_auth
                                    }
                        },
                    },
                },
                errors: {
                    is_errors: false,
                    actions_error: {},
                },
                business_logic: {
                    selected_profession: null,
                    selected_extension: null,
                    selected_extension_type: "",
                    selected_extension_unlock_lvl: 999
                },
            }

    export default {
        name: 'professions', /* MODIFY */
        data() {
            return {
                cdata: cdata,
            };
        },
        created() { },
        mounted() {
            document.getElementById('await_loader_outer').style.display = 'block';
            this.$store.dispatch('_system/vuex_set__route_', this.cdata.system._route_);
            this.$root._PAGE().on_mounted(this.$store, this.cdata); //обнулить флажки

            //IF NEEDED 

            setTimeout(() => {
                this.$store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': true, '_route_': this.cdata.system._route_ });
                this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            }, 300);
            //this.cdata.system.is_mounted_execute = true;    
            //this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            
            
//            REMOVE???!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___global__level_settings__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__exp_by_tasks__back(this.$store, this.cdata));
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__solve_limit__back(this.$store, this.cdata));
            
            
//            this.$nextTick(() => {
//                this.state.player_professions.forEach(profession => {
//                    if(profession.is_active == 1){
//                        this.cdata.business_logic.selected_profession = profession;
//                    }
//                });
//            });
        },
        computed: {

            /* COPYPASTE */
            ...mapGetters(['__blocked_tables', '__is_required_sections', '__is_required_stages', '__tables', '__is_errors']),
            _system() {
                let debug = {};
                try {
                    debug['tables'] = this.__tables(this.cdata['system']['_route_']);
                    debug['is_errors'] = this.__is_errors(this.cdata['system']['_route_']);
                    debug['blockings'] = this.__blocked_tables(this.cdata['system']['_route_']);
                    debug['sections'] = this.__is_required_sections(this.cdata['system']['_route_']);
                    debug['stages'] = this.__is_required_stages(this.cdata['system']['_route_']);
                } catch (ex) {
                    console.log(ex);
                }
                return debug;
            },
            /* MODIFY */
            state() {
                let state = {};
                try {
                    /* MODIFY */ //ВЫБРАТЬ используемые МОДУЛИ --> подгрузятся ТАБЛИЦЫ в них
                    state['auth'] = this.$store.state['state_tables'].state_auth; /* MODIFY */
                    
                    state['inventory_glossary'] = this.$store.state['state_tables'].state_game_settings.global__inventory_glossary;/* MODIFY */
                    state['player_inventory'] = this.$root._LAYOUT().sort_player_inventory(this.$store, this.cdata, this.$store.state.is_loaded_stages.player_inventory);
                    
                    state['player_current_profession'] = this.$root._LAYOUT().get_player_current_profession(this.$store, this.cdata, this.$store.state.is_loaded_stages.global__profession_list, this.$store.state.is_loaded_stages.player_professions);
                    state['player_professions'] = this.$root._LAYOUT().get_player_professions(this.$store, this.cdata, state['player_inventory'], true);
                } catch (ex) {
                    console.log(ex);
                }

                return state;
            },
            form() {
                let form = {}; // form.classnum


                form['cnt_loaded'] = this.$store.state.cnt_loaded;
                form['is_all_stage_fin'] = this.$store.state.is_all_stage_fin;
                form['classnum'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('classnum') ? this.$store.state.state_tables.state_auth.r_auth['classnum'] : "-1";
                form['lid'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('lid') ? this.$store.state.state_tables.state_auth.r_auth['lid'] : "-1";
                return form;
            },
            /* #ODIFY */
            /* #OPYPASTE */
            
            form_prof_tree_lvls() {
                let output = {
                    Formulas: {},
                    Items: {},
                    Boosters: {}
                };
                
                for(let i = 1; i <= Number(cdata.business_logic.selected_profession.max_level); i++) {
                    if(this.cdata.business_logic.selected_profession.prof_extensions.Formulas[i]){
                        output.Formulas[i] = this.cdata.business_logic.selected_profession.prof_extensions.Formulas[i];
                        if(!output.Items[i]) { output.Items[i] = null; }
                        if(!output.Boosters[i]) { output.Boosters[i] = null; }
                    }
                    
                    if(this.cdata.business_logic.selected_profession.prof_extensions.Items[i]){
                        output.Items[i] = this.cdata.business_logic.selected_profession.prof_extensions.Items[i];
                        if(!output.Formulas[i]) { output.Formulas[i] = null; }
                        if(!output.Boosters[i]) { output.Boosters[i] = null; }
                    }
                    
                    if(this.cdata.business_logic.selected_profession.prof_extensions.Boosters[i]){
                        output.Boosters[i] = this.cdata.business_logic.selected_profession.prof_extensions.Boosters[i];
                        if(!output.Formulas[i]) { output.Formulas[i] = null; }
                        if(!output.Items[i]) { output.Items[i] = null; }
                    }
                    
                }
                
                output.Formulas = Object.entries(output.Formulas).sort((a, b) => a[0] - b[0]);
                output.Items = Object.entries(output.Items).sort((a, b) => a[0] - b[0]);
                output.Boosters = Object.entries(output.Boosters).sort((a, b) => a[0] - b[0]);
//                Formulas Items Boosters
                return output;
            },

        },
        watch: {

            /* COPYPASTE на каждое изменение при работе с таблицей два вызова на начало и завершение*/
            "form.cnt_loaded": {
                handler: function () {



                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после выполнения всех стадий, на любое действие с таблицами
                    if (this.$store.state.is_all_stage_fin)
                    {
                        document.getElementById('await_loader_outer').style.display = 'none';
                        //console.log("EXECUTE AFTER ALL STAGE LOAD FIN [" + this.cdata.system._route_ + "]");
                    }


                    //ВЫПОЛНИТСЯ РАЗОВО - проверяем готовы ли стадии, для текущей страницы 
                    if (this.$store.state.system[this.cdata.system._route_].is_mounted_execute && !this.$store.state.system[cdata.system._route_].is_all_fin_for_this_page) {
                        //проверить завершение всех стадий для страницы (срабатывал раньше времени)
                        //set cdata.system.is_stages_fin_for_THIS_page
                        // console.log("CALC is_stages_fin_for_THIS_page");
                        if (this.$root._SYS().check_is_stages_fin_for_this_page(this.$store, this.cdata))
                        {
                            this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page',   {'is_all_fin_for_this_page': true, '_route_': this.cdata.system._route_ });
                            console.log("REGISTER ALL STAGE LOAD FIN - FOR PAGE [" + this.cdata.system._route_ + "]");
                        }


                    }


                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после загрузки всех стадий для страницы, на любое действие с таблицами
                    if (this.$store.state.system[cdata.system._route_].is_all_fin_for_this_page)
                    {
                        //просто постоянно перещитываем счетчики и блокровки кнопок!
                        this.$root._SYS().watch_force_called_on_mounted_execute(this.$store, this.cdata, true);
                    }



                },
                deep: true, //чтобы детектил изменения вложенных в объекто элементов
                immediate: true, //При передаче immediate: true в опциях, коллбэк будет вызываться незамедлительно с текущим значением отслеживаемого выражения:
            },
        },
        methods: {
            
            show_prof_tree: function () {
                let current_prof_id = event.target.getAttribute('current_id');
                
                let selected_profession = this.state.player_professions.find(prof => prof.prof_id == current_prof_id);
                
                this.cdata.business_logic.selected_profession = selected_profession;
                console.log(current_prof_id);
            },
            
            hide_prof_tree: function () {
                this.cdata.business_logic.selected_profession = null;
            },
            
            show_extension_modal: function(extension_unlock_lvl, row_name, extension_obj) {
                this.cdata.business_logic.selected_extension = extension_obj;
                this.cdata.business_logic.selected_extension_type = row_name;
                this.cdata.business_logic.selected_extension_unlock_lvl = extension_unlock_lvl;
            },
            hide_extension_modal: function() {
                this.cdata.business_logic.selected_extension = null;
            },
            
            unlock_new_extension: async function() {
                let res_exp_amount = this.$store.state['state_tables'].state_game_stats.player_inventory.find(item => item.item_id == "res_exp");
                
                if(res_exp_amount){
                    let res_exp_needed = Number(this.cdata.business_logic.selected_extension.unlock_cost);
                    let deal_res = Number(res_exp_amount.item_count) - res_exp_needed;

                    if(deal_res >= 0){
//                        await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().unlock_extension__multi_ls_and_back(this.$store, this.cdata));


                        await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().unlock_extension__multi_ls_and_back(this.$store, this.cdata)).then(
                            this.$snotify.async('Calling the spaceship', 'Loading', () => new Promise((resolve, reject) => {
                                setTimeout(() => resolve({
                                  title: 'Success!!!',
                                  body: 'We got a new extension!',
                                  config: {
                                    timeout: 3000,
                                    showProgressBar: true,
                                    pauseOnHover: true,
                                    closeOnClick: true
                                  }
                                }), 2000);
                            }))
                        );

                    }
                    
                    this.cdata.business_logic.selected_extension = null;
                    
                    let selected_profession = this.state.player_professions.find(prof => prof.prof_id == this.cdata.business_logic.selected_profession.prof_id);
                    this.cdata.business_logic.selected_profession = selected_profession;
                    
                }
            },
            
        },
        beforeDestroy() {
            this.cdata.business_logic.selected_extension = null;
            this.cdata.business_logic.selected_profession = null;
            this.cdata.business_logic.selected_extension_type = "";
            this.cdata.business_logic.selected_extension_unlock_lvl = 999;
        }


    }
</script>

<style lang="scss" scoped>
    
    .prof_card__outer:nth-of-type(2) ~ .prof_card__outer{
        margin-top: 3rem;
    }
    
    .prof_card {
        position:relative; 
        width: 270px;
        height: 340px; 
        background-color: white;
        box-shadow: 0px 4px 0px #E5E6EF; 
        border-radius: 12px;
        
        
        &.active {
            border: 2px solid #F6D45A;
        }
        
        & .prof_card_resources {
            width: 40px; 
            height:40px;
        }
    }
    
    .prof_card__header {
        & img{
            width: 40px; 
            height: 40px;
        }

        & p {
            font-size: 16px; 
            color: #2E3B4B;
        }

        & .prof_card__header__prof_name {
            font-weight: 600;
        }

        & .prof_card__header__prof_lvl_outer {
            padding: 4px 15px;
            background: #F4F5FD; 
            border-radius: 40px;
        }

        & .prof_card__header__prof_lvl {
            font-weight: 500;
        }
    }
    
    .active_prof_signboard__outer {
        position: absolute;
        bottom: -10%;
        left: 50%;
        transform: translate(-50%, 10%);
    }
    
    .active_prof_signboard {
        background: #F7D45A;
        border-radius: 0px 0px 8px 8px;
        padding: 2px 15px;
        height: 35px;
        
        & p {
            color: white;
            font-size: 20px;
            font-weight: 500;
        }
    }
    
    .prof_tree__header_info {
        & img {
            width: 40px;
            height: 40px;
        }

        & .prof_tree__header_info__prof_name {
            font-weight: 500;
            font-size: 24px;
            color: #2E3B4B;
        }

        & .prof_tree__header_info_lvl_outer {
            padding: 4px 12px;
            background: #FFFFFF;
            border-radius: 40px;
        }

        & .prof_tree__header_info_lvl {
            font-weight: 600;
            font-size: 15px;
            color: #2E3B4B;
        }
    }
    
    .prof_tree__header_resources img {
        width: 40px;
        height: 40px;
    }
    
    
    .prof_tree__content_header {        
        & p {
            font-weight: 500;
            font-size: 20px;
            color: #2E3B4B;
        }
        
        & img {
            width: 40px;
            height: 40px;
        }
    }
    

    @media (max-width: 1450px){
        .prof_card {
            width: 220px;
            height: 285px;
            
            & .prof_card__header {
                & img {
                    width: 30px;
                    height: 30px;
                }
                
                & p {
                    font-size: 14px;
                }
                
                & .prof_card__header__prof_lvl_outer {
                    padding: 4px 12px;
                }
            }
            
            & .prof_card_resources {
                width: 30px;
                height: 30px;
            }
            
            & .active_prof_signboard__outer {
                & .active_prof_signboard {
                    padding: 2px 10px;
                    height: 30px;
                }
                
                & p {
                    font-size: 16px;
                }
            }
        }
        
        .prof_tree__header {
            & .prof_tree__header_info img,
            & .prof_tree__header_resources img{
                width: 30px;
                height: 30px;
            }
            
            & .prof_tree__header_info__prof_name {
                font-size: 20px;
            }
            
            & .prof_tree__header_info_lvl {
                font-size: 12px;
            }
        }
        
        .prof_tree__content_header{
            & img{
                width: 30px;
                height: 30px;
            }
            
            & p {
                font-size: 16px;
            }
        }
    }
    
    .prof_picture_row {
        border-radius: 12px;
    }
    
    
    
    
 /*TREE*/   
.wrap{
    position: relative;
    overflow: hidden;
}

.box{
    overflow: hidden;
    margin-top: 48.5px; 
    /*margin-top: 20px;*/ 
    /*margin-top: 50px;*/
    /*display:none;*/
}







.box:first-child.test{
    margin-top: 0;
    
    & .general_extension_button_outer {
        border-top: none;
    }
}










.lvl_line {
    border-right: 2px solid #DCE0EA;
    height: 99%;
    left: 47.5px;
    position: absolute;
    top: 0;
    width: 2px;
    z-index: 2;
}

.box:last-child .lvl_line{
    display: none;
}





.general_extension_button_outer {
    position: relative;
    z-index: 5;
    border-bottom: 12px solid #f4f5fd;
    border-top: 12px solid #f4f5fd;
    
    display: inline-block;
    
    & .general_extension_button {
        border-radius: 12px;
        padding: 2px 5px;
        cursor: pointer;
        width: 95px;
        text-align: center;
    }
    
    & .general_extension_button_unlocked {
        width: 95px;
        text-align: center;
        border-radius: 12px;
        padding: 2px 5px;
        
        cursor: pointer;
    }
    
    & .general_extension_button.locked{
        background: #DCE0EA;
        box-shadow: none;
        cursor: default;
        border: 3px solid #DCE0EA;
    }
    
    & p {
        font-weight: 500;
        font-size: 17px;
        color: white;
    }
}




.general_extension_button__front {
  position: relative;
}

.general_extension_button__back {
    display:none;
}

.general_extension_button__front .general_extension_button__back {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 12px;
    transform: translate(-50%, -50%);
    
    visibility: hidden;
    opacity: 0;
    background: #f4f5fd;
    transition: all .2s ease-out;
}

.locked.general_extension_button__front .general_extension_button__back { 
    width: 100%;
}

.locked.general_extension_button__front:hover {
    background: #f4f5fd !important;
    box-shadow: none !important;
    border-radius: 12px;
    
    & p:first-child {
        visibility: hidden;
    }
}

.general_extension_button_unlocked.general_extension_button__front:hover {
    background: #f4f5fd !important;
    box-shadow: none !important;
    border-radius: 12px;
    
    & p:first-child {
        visibility: hidden;
    }
}

.general_extension_button__front:hover .general_extension_button__back {
    visibility: visible;
    opacity: 1;
    transition: all .2s ease-in;
    
    color: #2E3B4B;
    font-weight: 500;
    font-size: 17px;
    & i {
          color: #CACFDD;
    }
}



.prof_tree__content__formulas {
    & .general_extension_button {
        background: #49BDF4;
        box-shadow: 0px 4px 0px #34AEE8;       
        border: 3px solid #49BDF4;
    }
    
    & .general_extension_button_unlocked {
        border: 3px solid #49BDF4;
        background-color: rgb(244, 245, 253);
        
        & p {
            color: #49BDF4;
        }
    }
    
    & .locked.general_extension_button__front:hover {
        border: 3px solid #DCE0EA;
    }
    
    & .general_extension_button_unlocked.general_extension_button__front:hover {
        border: 3px solid #49BDF4;
    }
}


.prof_tree__content__items {
    & .general_extension_button {
        background: #8C90FF;
        box-shadow: 0px 4px 0px #696DD3;      
        border: 3px solid #8C90FF;
    }
    
    & .general_extension_button_unlocked {
        border: 3px solid #8C90FF;
        background-color: rgb(244, 245, 253);
        
        & p {
            color: #8C90FF;
        }
    }
    
    & .locked.general_extension_button__front:hover {
        border: 3px solid #DCE0EA;
    }
    
    & .general_extension_button_unlocked.general_extension_button__front:hover {
        border: 3px solid #8C90FF;
    }
}

.prof_tree__content__boosters {
    & .general_extension_button {
        background: #FF9E4A;
        box-shadow: 0px 4px 0px #F38220;   
        border: 3px solid #FF9E4A;
    }
    
    & .general_extension_button_unlocked {
        border: 3px solid #FF9E4A;
        background-color: rgb(244, 245, 253);
        
        & p {
            color: #FF9E4A;
        }
    }
    
    & .locked.general_extension_button__front:hover {
        border: 3px solid #DCE0EA;
    }
    
    & .general_extension_button_unlocked.general_extension_button__front:hover {
        border: 3px solid #FF9E4A;
    }
}


.prof_tree__content {
/*    height: 500px;
    overflow-x: hidden;
    overflow-y: auto;*/
}


 /*END TREE*/  
    

 
 
 
 
 
 
 
 
/*MODAL*/
.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    outline: 0;
    background: #d3d3d38c;
    
    & .custom_modal__body_tier {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        color: #2E3B4B;
    }
    
    & .modal-content {
        border: none;
        border-radius: 16px;
        padding: 24px;
    }
    
    & .custom_modal__header {
        display:flex;
        align-items: flex-start;
        justify-content: space-between;
        
        & h5 {
            color: #2E3B4B;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
        }
    }
    
    & .chain_part {
        & p {
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            color: #2E3B4B;
        }
        
        & img {
            width: 28px;
        }
    }
    
    & .custom_modal__body button {
        border: none;
        padding: 10px 24px;
        width: 100%;
        border-radius: 12px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color: #FFFFFF;
    }
    
    & .custom_modal__body .btn_Formula {
        background: #49BDF4;
        box-shadow: 0px 4px 0px #319CCF;
    }
    
    & .custom_modal__body .btn_Item {
        background: #8C90FF;
        box-shadow: 0px 4px 0px #696DD3;
    }
    
    & .custom_modal__body .btn_Booster {
        background: #FF9E4A;
        box-shadow: 0px 4px 0px #F38220;
    }
    
    & .custom_modal__body .btn_inactive {
        background: #DCE0EA;
        box-shadow: 0px 4px 0px #C5C9D2;
        cursor: default;
    }
}
/*END MODAL*/

.booster_usage_limit {
    display: flex;
    padding: 3px 12px 3px 10px;
    border: 1px solid #49BDF4;
    border-radius: 8px;
    background: #DDF4FF;
    
    & p {
        color: #2E3B4B;
        /*font-family: 'Mplus 1p';*/
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
    }
    
    & img {
        width: 20px;
    }
}
    
    .v-enter-active,
    .v-leave-active {
      transition: opacity 0.5s ease;
    }

    .v-enter-from,
    .v-leave-to {
      opacity: 0;
    }
    

</style>
